import React from 'react';
import classNames from 'classnames';
import styles from './HomeBanner.module.scss';
import { Image } from '../../components/Image';

export default function DownloadPop({ hover, qrcode }: { hover: boolean; qrcode: string }) {
  return (
    <>
      <div className={classNames(styles.downloadAppPic, hover && styles.showPic)}>
        <Image src={qrcode} layout="fill" />
      </div>
      <div className={classNames(styles.downloadAppPop, hover && styles.showPop)}>
        <svg fill="none" width="892" height="186" viewBox="0 0 892 186">
          <defs>
            <mask id="a">
              <rect width="892" height="186" rx="0" fill="#FFF" />
            </mask>
            <filter
              id="b"
              filterUnits="objectBoundingBox"
              colorInterpolationFilters="sRGB"
              x="0"
              y="0"
              width="800"
              height="554"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="5" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_foregroundBlur" />
              <feBlend in="SourceGraphic" in2="effect1_foregroundBlur" result="shape" />
            </filter>
            <linearGradient x1=".5" y1=".679" x2=".5" y2="1" id="c">
              <stop offset="0%" stopColor="#FFF" stopOpacity="0" />
              <stop offset="42.618%" stopColor="#FFF" stopOpacity=".85" />
              <stop offset="100%" stopColor="#F8F9FA" stopOpacity=".75" />
            </linearGradient>
            <linearGradient x1=".5" x2=".5" y2="1" id="d">
              <stop offset="63.704%" stopColor="#FFF" stopOpacity="0" />
              <stop offset="100%" stopColor="#FFF" stopOpacity=".5" />
            </linearGradient>
          </defs>
          <g filter="url(#b)" mask="url(#a)">
            <ellipse cx="442" cy="-98" rx="400" ry="277" fill="url(#c)" />
            <ellipse cx="442" cy="-98" rx="399" ry="276" stroke="url(#d)" strokeWidth="2" />
          </g>
        </svg>
      </div>
    </>
  );
}
