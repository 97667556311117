import React from 'react';
import { serverSideTranslation } from '../util/next';
import Page from '../components/Layout/Page';
import TitleLink from '../components/TitleLink';
import ArrowBtn from '../components/ArrowBtn';
import HomeBanner from '../modules/HomeBanner';
import { getPositionListNewsList } from '../api/newsApi';
import type { NewsCenterItem } from '../api/newsApi';
import { getPageContent } from '../api/pagesApi';
import { genShareConfig } from '../components/Share/use-config-share';
import { catLog, STATUS } from '../util/Cat';

import { NewsItem } from '../components/NewsList/Item';
import { Container } from '../components/NewsList/Container';
import { NewsCenter } from '../modules/NewsCenter/Center';

interface Props {
  pageContentData: any;
  newsMainData: NewsCenterItem;
  // newsCenterData: NewsCenterItem[];
  newsMarketData: NewsCenterItem[];
}

const Home = (props: Props) => {
  const { newsMainData, newsMarketData, pageContentData } = props;
  // const newsDataMo = newsCenterData.concat(newsMarketData);
  const nextNewsData = newsMarketData[0];
  const newsListData = newsMarketData.slice(1);

  const { pageContent = {}, shareContent = {} } = pageContentData || {};
  const {
    homeMoImg,
    homeLeftImg,
    homeRightImg,
    homeTopBannerInfo,
    homeNewsCenter,
    homeNewsButton,
    homeCenterPcImg,
    homeCenterPcHoverImg,
  } = pageContent;

  return (
    <Page title={shareContent.shareTitle}>
      {/* 顶部banner模块 */}
      <div className="w-full bg-[#f7f8f9]">
        <HomeBanner
          bannerInfo={homeTopBannerInfo}
          homeMoImg={homeMoImg}
          homeLeftImg={homeLeftImg}
          homeRightImg={homeRightImg}
          homeCenterPcImg={homeCenterPcImg}
          homeCenterPcHoverImg={homeCenterPcHoverImg}
        />
      </div>
      <div className="w-full bg-white-homebg">
        {/* 新闻最新动态 */}
        <div className="mx-5 lg:max-w-screen-xl lg:pt-0 l:mx-auto">
          <div className="pb-[18px] pt-[30px] lg:pb-[18px] lg:pt-[3px]">
            <TitleLink
              textVal={homeNewsCenter.title}
              linkVal={homeNewsCenter.actionText}
              linkUrl={homeNewsCenter.actionUrl}
              linkOut
            />
          </div>
          {/* 约定从左至右、从上至下的新闻顺序，分别用position: number 1->9表示，用于埋点记录 */}
          {/* 第一行新闻 */}
          {/* <NewsCenter newsMainData={newsMainData} newsListData={newsCenterData} /> */}
          <NewsCenter mainNewsData={newsMainData} nextNewsData={nextNewsData} />
          {/* 后续六条新闻 */}
          <Container>
            {newsListData.map((_, index) => (
              /* position参数从3开始递增 */
              <NewsItem newsItemData={_} key={index} position={3 + index} />
            ))}
          </Container>
          <div className="flex justify-center pb-10 pt-6 lg:pb-25 lg:pt-10">
            <ArrowBtn linkVal={homeNewsButton.actionText} linkUrl={homeNewsButton.actionUrl} linkOut />
          </div>
        </div>
      </div>
    </Page>
  );
};
// 获取首页数据
export async function getServerSideProps({ locale, query }: any) {
  const catlog = catLog('PageServer', '/index');
  let newsMainData;
  let newsMarketData;
  let pageContentData;
  try {
    // 上报接口耗时
    const pageContentParams = {
      uri: '/home',
      pageContentNo: query.pageContentNo,
      preview: query.preview,
    };
    const positionNewsList = await getPositionListNewsList({
      lanType: locale,
      positionNoList: ['msgcenter_1', 'msgcenter_3'],
    });
    newsMainData = positionNewsList.msgcenter_1?.slice(0, 1);
    // const newsCenterData = positionNewsList.msgcenter_2?.slice(0, 3);
    newsMarketData = positionNewsList.msgcenter_3?.slice(0, 7);
    pageContentData = await getPageContent(pageContentParams, locale);
    catlog.complete();
  } catch (error: any) {
    catlog.setStatus(STATUS.FAIL);
    catlog.logError('serverRequestError', error);
    catlog.complete();
    throw error;
  }

  return {
    props: {
      lxCustom: {
        cid: 'c_donation_47g9oy78',
        locale,
        ...pageContentData?.shareContent,
      },
      pageContentData,
      locale,
      newsMainData: newsMainData?.[0] || {},
      // newsCenterData,
      newsMarketData,
      ...genShareConfig(pageContentData),
      ...(await serverSideTranslation(locale, ['home', 'cookie', 'common'])),
    },
  };
}
export default Home;
