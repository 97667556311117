import React, { FC } from 'react';
// import Link from 'next/link';
import { Link } from '../Link';
import Arrow from '../Arrow';
import styles from './ArrowBtn.module.scss';

interface Props {
  linkVal?: string;
  linkUrl?: string;
  linkOut?: boolean;
}
const ArrowBtn: FC<Props> = (props) => {
  const { linkVal = '', linkUrl = '', linkOut = false } = props;
  return (
    <div className={styles['arrow-btn-box']}>
      {linkVal && (
        <Link href={linkUrl || ''} legacyBehavior scroll={false} prefetch={false}>
          <a className={styles['arrow-btn-wrap-box']} target={linkOut ? '_blank' : '_self'}>
            <div className={styles['arrow-btn-text']}>{linkVal}</div>
            <div className={styles['arrow-btn-arrow']}>
              <Arrow className="h-4 w-4 lg:h-6 lg:w-6" />
            </div>
          </a>
        </Link>
      )}
    </div>
  );
};

ArrowBtn.displayName = 'ArrowBtn';
export default ArrowBtn;
