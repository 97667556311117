import classNames from 'classnames';
import React, { FC } from 'react';
import type { SizeType } from '../../types/index';

import styles from './PlayerIcon.module.scss';

interface Props {
  size?: SizeType;
}
const PlayerIcon: FC<Props> = (props) => {
  const { size = 'middle' } = props;
  const iconboxClass = {
    small: 'h-[25px] w-[25px] pl-[2px] lg:pl-[3px] lg:h-[50px] lg:w-[50px]',
    middle: 'h-[50px] w-[50px] pl-[4px] lg:pl-[6px] lg:h-[66px] lg:w-[66px]',
    large: 'h-[66px] w-[66px] pl-[6px] lg:pl-[10px] lg:h-[100px] lg:w-[100px]',
  };
  const iconClass = {
    small: 'h-2 w-2 lg:h-4 lg:w-4',
    middle: 'h-4 w-4 lg:h-5 lg:w-5',
    large: 'h-5 w-5 lg:h-6 lg:w-6',
  };

  return (
    <div className={styles['player-icon-mask']}>
      <div className={classNames(styles['player-icon-box'], iconboxClass[size])}>
        <svg
          className={classNames(styles['player-icon'], iconClass[size])}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          version="1.1"
          width="20"
          height="23.33333396911621"
          viewBox="0 0 20 23.33333396911621"
        >
          <g>
            <path
              d="M18.0256,12.8184L2.00516,22.1637C1.11629,22.6822,0,22.041,0,21.012L0,2.32138C0,1.29233,1.11629,0.651167,2.00516,1.16968L18.0256,10.515C18.9077,11.0295,18.9077,12.3039,18.0256,12.8184"
              fill="#FFFFFF"
              fillOpacity="1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

PlayerIcon.displayName = 'PlayerIcon';
export default PlayerIcon;
