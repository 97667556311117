import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { HomepageMeta } from '../../data/dataDef';

const Page: NextPage<HomepageMeta> = ({ children, title }) => {
  const { asPath: pathname } = useRouter();
  const trans = useTranslation('common');
  const { t } = trans;

  return (
    <>
      <Head>
        <title>{isEmpty(title) ? t('pageConfig.pageTitle') : title}</title>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <link href={`https://www.meituan.com${pathname}`} key="canonical" rel="canonical" />
        <link href="/site.webmanifest" rel="manifest" />
        {/* 禁止页面嵌入 */}
        {/* <meta httpEquiv="X-FRAME-OPTIONS" content="DENY" /> */}
      </Head>
      {children}
    </>
  );
};

Page.displayName = 'Page';
export default Page;
