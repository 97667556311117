import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from '../SvgIcon';

interface Props {
  className?: string;
  // theme?: string;
}
const Arrow: FC<Props> = (props) => {
  const { className } = props;
  const ArrowClassName = className || 'w-1.125r h-1.125r';
  // todo 其他风格枚举
  // const fillcolor = theme === 'black' ? '#fff' : '#000';

  return (
    <Icon name="arrow" className={classNames(ArrowClassName)} />
    // <svg className={ArrowClassName} viewBox="0 0 36 36">
    //   {/* <g transform="translate(-372.000000, -556.000000)"> */}
    //   {/* <g
    //       id="right-thick"
    //       transform="translate(390.000000, 574.000000) rotate(-360.000000) translate(-390.000000, -574.000000) translate(372.000000, 556.000000)"
    //     > */}
    //   <path
    //     d="M20.205,18.045 L13.5456426,11.3869825 C13.1062039,10.9476322 13.1060842,10.235248 13.545375,9.79574999 C13.984545,9.35637297 14.696748,9.35620512 15.1361251,9.79537509 C15.1361876,9.79543756 15.13625,9.79550004 15.1363125,9.79556252 L22.85625,17.5155 L22.85625,17.5155 C23.1490364,17.8083749 23.1490364,18.2831251 22.85625,18.576 L15.135105,26.295105 C14.6959636,26.7341304 13.9840834,26.7340834 13.545,26.295 C13.1059336,25.8559336 13.1059336,25.1440664 13.545,24.705 L20.205,18.045 L20.205,18.045 Z"
    //     fill={fillcolor}
    //   />
    //   {/* </g> */}
    //   {/* </g> */}
    // </svg>
  );
};

Arrow.displayName = 'Arrow';
export default Arrow;
