import React, { FC } from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
import { Link } from '../Link';
import Arrow from '../Arrow';
import styles from './TitleLink.module.scss';

interface Props {
  className?: string;
  textVal?: string;
  linkVal?: string;
  linkUrl?: string;
  title?: () => React.ReactNode;
  titleLinkTheme?: string;
  linkOut?: boolean;
}
const TitleLink: FC<Props> = (props) => {
  const { className, textVal = '', linkVal = '', linkUrl = '', titleLinkTheme, title, linkOut = false } = props;
  const fontClassName = titleLinkTheme ? ' text-white' : 'text-black';
  return (
    <div className={classNames(fontClassName, styles['title-link-box'], className)}>
      {title ? title() : <h2 className={styles['link-title']}>{textVal}</h2>}
      {linkVal && (
        <Link href={linkUrl || ''} legacyBehavior prefetch={false}>
          <a className={styles['link-action-box']} target={linkOut ? '_blank' : '_self'}>
            <div className={styles['link-action-text']}>{linkVal}</div>
            <div className={styles['link-action-arrow']}>
              <Arrow />
            </div>
          </a>
        </Link>
      )}
    </div>
  );
};

TitleLink.displayName = 'TitleLink';
export default TitleLink;
