import { useMemo } from 'react';
import { useScreenSize, BreakPointSize } from '../../ScreenSize';

type BreakPoint = BreakPointSize.md | BreakPointSize.sm;

export const useNewsSize = (breakpoint: BreakPoint) => {
  const { size } = useScreenSize();

  const [imgWidth, imgHeight] = useMemo(() => {
    if (size.width < breakpoint) {
      return [335, 250];
    }
    return [413, 310];
  }, [size]);

  return [imgWidth, imgHeight];
};
