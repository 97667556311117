import React from 'react';
// import Link from 'next/link';
import { Link } from '../Link';
import ExposureContainer from '../ExposureContainer';
import { useNewsItemModuleViewParams, useNewsItemModuleClickParams } from './hooks/useLxParams';
import type { NewsCenterItem } from '../../api/newsApi';
import { Image } from '../Image';
import PlayerIcon from '../PlayerIcon';
import styles from './item.module.scss';
import { useModuleClick } from '../Layout/lxContext';
// import { moduleClick } from '../../util/lx';
import { BreakPointSize } from '../ScreenSize';
import { useNewsSize } from './hooks/useNewsSize';
import { newsCoverCompressOptions } from '../../util/imgLoader';
import useNewsHref from './hooks/useNewsHref';

interface Props {
  newsItemData: Partial<NewsCenterItem>;
  /* 标识新闻模块所处的位置，用于埋点 */
  position?: number;
  /* next/image的属性，标明加载方式，默认为lazy懒加载 */
  loading?: string;
}

export const NewsItem = (props: Props) => {
  const { newsItemData = {}, position, loading } = props;
  const { newsImg, newsNo, newsTitle, newsAbstract, newsDate, newsCardType } = newsItemData;

  const isVideo = newsCardType === 'VIDEO';

  const moduleViewParam = useNewsItemModuleViewParams({ position, newsNo, newsTitle });
  const moduleClickParam = useNewsItemModuleClickParams({ position, newsNo, newsTitle });
  const moduleClick = useModuleClick();
  const { handleClickImg, href } = useNewsHref({
    newsNo: newsItemData.newsNo as string,
    newsCardType: newsItemData.newsCardType,
  });
  const handleClick = () => {
    moduleClick?.(moduleClickParam);
  };

  const [imgWidth, imgHeight] = useNewsSize(BreakPointSize.md);

  return (
    <Link href={href} prefetch={false} legacyBehavior>
      <a target="_blank" className={styles.NewsItem}>
        <ExposureContainer onClick={handleClick} className={styles.NewsItemWrap} moduleViewParam={moduleViewParam}>
          <>
            <div onClick={handleClickImg} className={styles.NewsItemImg}>
              <div className={styles['img-box']}>
                <Image
                  layout="responsive"
                  objectFit="cover"
                  width={imgWidth}
                  height={imgHeight}
                  loading={loading}
                  src={newsImg as string}
                  compressOptions={newsCoverCompressOptions}
                />
              </div>
              {isVideo && <PlayerIcon />}
            </div>
            <div className={styles.NewsItemContent}>
              <h6 className={styles.title}>{newsTitle}</h6>
              <div className={styles.abstract}>{newsAbstract}</div>
              <time className={styles.times}>{newsDate}</time>
            </div>
          </>
        </ExposureContainer>
      </a>
    </Link>
  );
};
