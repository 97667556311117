import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './MTDIcon.module.scss';

export interface MTDIconProps extends React.HTMLAttributes<SVGSVGElement> {
  icon: string;
  height?: string;
  width?: string;
  svgRef?: React.Ref<SVGSVGElement>;
  transform?: string;
}

const MTDIcon: FC<MTDIconProps> = ({ icon, className, svgRef, transform, height, width, ...props }) => {
  const finalCls = classNames(styles['cus-mtdicon'], className);
  const finalWidth = width || '1rem';
  const finalHeight = height || '1rem';
  const finalIcon = `#${icon}`;
  return (
    <svg
      className={finalCls}
      ref={svgRef}
      transform={transform}
      width={finalWidth}
      height={finalHeight}
      aria-hidden="true"
      {...props}
    >
      <use xlinkHref={finalIcon} />
    </svg>
  );
};

export default MTDIcon;
