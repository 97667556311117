import React from 'react';
import { NewsCenterItem as NewsCenterItemType } from '../../api/newsApi';
import { NewsItem } from '../../components/NewsList/Item';
import { NewsCenterItem } from '../../components/NewsList/NewsCenterItem';
import { Container } from '../../components/NewsList/Container';

interface Props {
  mainNewsData: NewsCenterItemType;
  nextNewsData: NewsCenterItemType;
}

export const NewsCenter = (props: Props) => {
  const { nextNewsData, mainNewsData } = props;

  /* 首页两张图默认优先加载，降低首屏时间 */
  const loading = 'eager';

  return (
    <Container>
      {/* position为1 */}
      <NewsCenterItem newsItemData={mainNewsData} position={1} loading={loading} />
      {/* position为2 */}
      <NewsItem newsItemData={nextNewsData} position={2} loading={loading} />
    </Container>
  );
};
