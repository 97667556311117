import { useMemo } from 'react';

export const useImage = function (imageUrl: string, width: number, quality = 60) {
  return useMemo(() => {
    const isHashandle = imageUrl?.includes('@');
    const isJPG = imageUrl?.includes('.jpg') || imageUrl?.includes('.jpeg');
    const isPNG = imageUrl?.includes('.png');
    if (isHashandle) {
      return '';
    }
    if (isJPG) {
      return `@${width}w_${quality}Q%7Cpr=1`;
    }
    if (isPNG) {
      return `@format=jpeg%7C${width}w_${quality}Q%7Cpr=1`;
    }
    return `@${width}w`;
  }, [imageUrl, width]);
};
