import { useMemo, useState } from 'react';

interface Props {
  newsNo: string;
  source?: string;
  newsCardType?: string;
}

export default function useNewsHref(props: Props) {
  const { newsNo, source, newsCardType } = props;
  const [autoPlay, setAutoPlay] = useState<string>();

  const handleClickImg = () => {
    if (newsCardType === 'VIDEO') {
      setAutoPlay('on');
      const timer = setTimeout(() => {
        setAutoPlay(undefined);
        clearTimeout(timer);
      }, 500);
    }
  };

  const href = useMemo(() => {
    let newsUrl = `/news/${newsNo}`;
    const searchParams = new URLSearchParams();
    if (source) {
      searchParams.append('source', source);
    }
    if (autoPlay) {
      searchParams.append('autoPlay', autoPlay);
    }
    const search = searchParams.toString();
    if (search) {
      newsUrl += `?${search}`;
    }
    return newsUrl;
  }, [newsNo, autoPlay, source]);

  return {
    href,
    handleClickImg,
  };
}
