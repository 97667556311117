import React, { createRef, RefObject } from 'react';
import { LxContext } from '../Layout/lxContext';
import { LXModuleParams } from '../../util/lx';

export interface ExposureContainerProps {
  children: JSX.Element;
  isAlways?: boolean;
  moduleViewParam?: LXModuleParams;
  onExpose?: (dom: Element) => void;
  onHide?: (dom: Element) => void;
  onClick?: (e: any) => void;
  className?: string;
  style?: any;
}
/**
 * @param moduleViewParam 曝光上报的参数，若提供则会在子组件曝光时自动上报 moduleView 并执行回调， 不提供则只执行回调
 * @param isAlways boolean，默认为 true, 表示会重复执行曝光上报和回调
 * @param onExpose 曝光回调，可为空
 * @param onHide 隐藏回调，可为空
 */
class ExposureContainer extends React.Component<ExposureContainerProps, {}> {
  domRef: RefObject<HTMLDivElement> = createRef();

  IOInstance: IntersectionObserver | null = null;

  isExposed = false;

  isHided = false;

  static contextType = LxContext;

  componentDidMount() {
    const { moduleViewParam, onHide, onExpose, isAlways = true } = this.props;
    // 新建一个 IntersectionObserver 对象
    // 兼容chrome 50以下
    if (!window.IntersectionObserver) {
      return;
    }
    this.IOInstance = new IntersectionObserver((entries) => {
      const entery = entries[0];
      const ratio = entery.intersectionRatio;
      if (ratio > 0) {
        // 处理always
        if (!isAlways) {
          if (this.isExposed) {
            // isAlways 为false，且曝光过，则不执行
            return;
          }
          this.isExposed = true;
        }

        if (moduleViewParam) {
          const timer = setTimeout(() => {
            const { moduleView } = this.context as any;
            moduleView?.(moduleViewParam);
            clearTimeout(timer);
          }, 30);
        }

        if (typeof onExpose === 'function') {
          onExpose(entery.target);
        }
      } else if (ratio <= 0) {
        // 处理always
        if (!isAlways) {
          if (this.isHided) {
            // isAlways 为false，且隐藏过，则不执行
            return;
          }
          this.isHided = true;
        }

        if (typeof onHide === 'function') {
          onHide(entery.target);
        }
      }
    });
    if (this.domRef.current) {
      this.IOInstance.observe(this.domRef.current);
    }
  }

  componentWillUnmount() {
    if (this.domRef.current) {
      this.IOInstance?.unobserve(this.domRef.current);
    }
  }

  render() {
    const { className, style, onClick, children } = this.props;
    return (
      <div ref={this.domRef} className={className || ''} style={style} onClick={onClick}>
        {children}
      </div>
    );
  }
}

export default ExposureContainer;
