import classNames from 'classnames';
import React from 'react';
import styles from './HomeBanner.module.scss';

interface Props {
  lg: string; // 1024 - 1280展示的图片
  xl: string; // 1280 以上展示的图片
  className: string;
}

export const Decoration = (props: Props) => {
  const { lg, xl, className } = props;

  const cls = classNames(className, `hidden lg:block`, styles.decoration);

  return (
    <div
      className={cls}
      style={
        {
          '--1024-bg': `url('${lg}')`,
          '--1280-bg': `url('${xl}')`,
        } as any
      }
    />
  );
};
