import { useMemo } from 'react';
import { LXModuleParams } from '../../../util/lx';

interface Args {
  position?: number;
  newsNo?: string;
  newsTitle?: string;
}

const useNewsItemModuleParams: (bid: string, args: Args) => LXModuleParams = (bid, { position, newsNo, newsTitle }) => {
  const moduleParams = useMemo(() => {
    return {
      bid,
      custom: {
        position,
        newsNo,
        newsTitle,
      },
    };
  }, [bid, position, newsNo, newsTitle]);
  return moduleParams;
};

export const useNewsItemModuleClickParams: (args: Args) => LXModuleParams = (args) => {
  return useNewsItemModuleParams('b_donation_ogzfdgy1_mc', args);
};

export const useNewsItemModuleViewParams: (args: Args) => LXModuleParams = (args) => {
  return useNewsItemModuleParams('b_donation_pou0pxwc_mv', args);
};
