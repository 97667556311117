import React, { FC, useMemo, useState } from 'react';
import Head from 'next/head';
import classNames from 'classnames';
import { useScreenSize } from '../../components/ScreenSize';
import Arrow from '../../components/Arrow';
import { MTDIcon } from '../../components/Icon';

import DownloadPop from './DownloadPop';
import styles from './HomeBanner.module.scss';
import { Decoration } from './Decoration';

export interface BriefProps {
  bannerInfo: Record<string, string>;
  homeMoImg: string;
  homeLeftImg: string;
  homeRightImg: string;
  homeCenterPcImg: string;
  homeCenterPcHoverImg: string;
}

const HomeBanner: FC<BriefProps> = (props) => {
  const { bannerInfo, homeMoImg, homeLeftImg, homeRightImg, homeCenterPcImg, homeCenterPcHoverImg } = props;
  const [hover, setHover] = useState(false);
  const [showInfoDetail, setShowInfoDetail] = useState(true);

  const { size } = useScreenSize(); // 页面尺寸属性
  useMemo(() => {
    if (size.width <= 1024) {
      setShowInfoDetail(false);
      return true;
    }
    setShowInfoDetail(true);
    return false;
  }, [size]);

  const {
    mainTitle,
    assistTitle1,
    introText1,
    introText2,
    introText3,
    introText4,
    introText5,
    introText6,
    introText7,
    downloadApp,
    downloadQrCode,
  } = bannerInfo;

  const intro = (
    <>
      {/* 介绍信息 */}
      <div className={classNames(styles['content-paragraph-box'], styles['content-paragraph-top-box'])}>
        <p className={styles['content-paragraph']}>{introText1}</p>
        <p className={styles['content-paragraph']}>{introText2}</p>
        <p className={styles['content-paragraph']}>{introText3}</p>
      </div>
      <div className={classNames(styles['content-paragraph-box'], styles['content-paragraph-bottom-box'])}>
        <p className={styles['content-paragraph']}>{introText4}</p>
        <p className={styles['content-paragraph']}>{introText5}</p>
        <p className={styles['content-paragraph']}>{introText6}</p>
        <p className={styles['content-paragraph']}>{introText7}</p>
      </div>
    </>
  );

  return (
    <section
      className={classNames(styles['home-banner-box'], 'pt-15 xl:pt-20', hover && styles['home-banner-hover-box'])}
      style={
        {
          '--auto-bg': `url(${homeMoImg})`,
          '--1024-bg': `url(${homeCenterPcImg})`,
          '--1024-hover-bg': `url(${homeCenterPcHoverImg})`,
        } as any
      }
    >
      <Head>
        {/* hover的图片预加载 */}
        <link rel="preload" href={homeCenterPcHoverImg} as="image" />
      </Head>
      <div className={styles['home-banner-content']}>
        {/* 主标题 */}
        <h1 className={classNames(styles['content-main-title'], 'mt-font')}>{mainTitle}</h1>
        {/* 副标题 */}
        <div className={styles['content-assist-title-box']}>
          <span className={styles['content-assist-title']}>
            {assistTitle1[0]}
            <span className={`${styles.light} ${styles.lightLeft}`}>“</span>
            {assistTitle1[1]}
            <span className={`${styles.light} ${styles.lightRight}`}>”</span>
            {assistTitle1[2]}
          </span>
          <span className={`${styles['content-assist-title']} pt-[10px] lg:pt-0`}>
            {assistTitle1[3]}
            <span className={`${styles.light} ${styles.lightLeft}`}>“</span>
            {assistTitle1[4]}
            <span className={`${styles.light} ${styles.lightRight}`}>”</span>
            {assistTitle1[5]}
          </span>
        </div>
        {/* PC端 */}
        <div className="hidden lg:block">{intro}</div>
        {/* 移动端 */}
        {showInfoDetail ? (
          <div className="block lg:hidden">{intro}</div>
        ) : (
          <div
            className={classNames(styles['show-info-btn-box'])}
            onClick={() => {
              setShowInfoDetail(true);
            }}
          >
            <div className={styles['show-info-btn']}>了解更多</div>
            <div className={styles['show-info-arrow']}>
              <Arrow />
            </div>
          </div>
        )}

        <div className={styles.downloadApp}>
          <span
            className={styles.downloadAppText}
            onMouseOver={() => setHover(true)}
            onFocus={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <MTDIcon width="26" height="26" className={styles.downloadAppIcon} icon="mtdicon-qrcode" />
            {downloadApp}
          </span>
          <DownloadPop hover={hover} qrcode={downloadQrCode} />
        </div>
      </div>
      <Decoration className={styles.bannerLeft} lg={homeLeftImg} xl={homeLeftImg} />
      <Decoration className={styles.bannerRight} lg={homeRightImg} xl={homeRightImg} />
    </section>
  );
};

HomeBanner.displayName = 'HomeBanner';
export default HomeBanner;
