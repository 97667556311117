import React from 'react';
// import Link from 'next/link';
import classNames from 'classnames';
import { Link } from '../Link';
import ExposureContainer from '../ExposureContainer';
import { useNewsItemModuleViewParams, useNewsItemModuleClickParams } from './hooks/useLxParams';
import type { NewsCenterItem as NewsCenterItemType } from '../../api/newsApi';
import { Image } from '../Image';
import { useImage } from '../../hooks/useImage';
import PlayerIcon from '../PlayerIcon';
import styles from './item.module.scss';
import { useModuleClick } from '../Layout/lxContext';
import useNewsHref from './hooks/useNewsHref';

interface Props {
  newsItemData: Partial<NewsCenterItemType>;
  /* 标识新闻模块所处的位置，用于埋点 */
  position?: number;
  /* next/image的属性，标明加载方式，默认为lazy懒加载 */
  loading?: string;
}

export const NewsCenterItem = (props: Props) => {
  const { newsItemData = {}, position, loading } = props;
  const { newsImg, newsFocusImg, newsNo, newsTitle, newsAbstract, newsDate, newsCardType } = newsItemData;

  const isVideo = newsCardType === 'VIDEO';

  const moduleViewParam = useNewsItemModuleViewParams({ position, newsNo, newsTitle });
  const moduleClickParam = useNewsItemModuleClickParams({ position, newsNo, newsTitle });

  const moduleClick = useModuleClick();

  const handleClick = () => {
    moduleClick?.(moduleClickParam);
  };

  const suffix = useImage(newsFocusImg as string, 1334);
  const mobliesuffix = useImage(newsImg as string, 620);

  const { handleClickImg, href } = useNewsHref({
    newsNo: newsItemData.newsNo as string,
    newsCardType: newsItemData.newsCardType,
  });

  return (
    <Link href={href} prefetch={false} legacyBehavior>
      <a target="_blank" className={styles.NewsCenterItem}>
        <ExposureContainer
          onClick={handleClick}
          className={styles.NewsCenterItemWrap}
          moduleViewParam={moduleViewParam}
        >
          <>
            <div onClick={handleClickImg} className={styles.NewsCenterItemImg}>
              <div className={classNames(styles['img-box'], 'hidden md:block')}>
                <Image
                  layout="responsive"
                  objectFit="cover"
                  width={846}
                  height={310}
                  loading={loading}
                  src={`${newsFocusImg}${suffix}` as string}
                />
              </div>
              <div className={classNames(styles['img-box'], 'block md:hidden')}>
                <Image
                  layout="responsive"
                  objectFit="cover"
                  width={335}
                  height={250}
                  loading={loading}
                  src={`${newsImg}${mobliesuffix}` as string}
                />
              </div>
              {isVideo && <PlayerIcon />}
            </div>
            <div className={styles.NewsCenterItemContent}>
              <h6 className={styles.title}>{newsTitle}</h6>
              <div className={styles.abstract}>{newsAbstract}</div>
              <time className={styles.times}>{newsDate}</time>
            </div>
          </>
        </ExposureContainer>
      </a>
    </Link>
  );
};
