import React from 'react';
import styles from './item.module.scss';

interface Props {
  children: React.ReactNode;
}

export const Container = (props: Props) => {
  const { children } = props;
  return (
    <div className={styles.container} owl-ignore="true">
      <div className={styles.containerInner}>{children}</div>
    </div>
  );
};
